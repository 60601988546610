<template>
  <mobile-screen :header="true" screen-class="edit-form-screen add-country">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="country-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_countries' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("countries", "add-country", "add-country") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      @submit="submitForm"
      v-slot="{ errors }"
      class="form tiny-input edit-form"
      novalidate
    >
      <div class="form-group input">
        <label class="tiny-label">
          {{ displayLabelName("countries", "add-country", "name") }}
        </label>
        <Field
          name="name"
          as="input"
          type="text"
          rules="required"
          :disabled="submitting"
        />
        <span class="error-message" v-if="errors.name">
          {{ errors.name }}
        </span>
      </div>
      <div class="form-group input">
        <label class="tiny-label">
          {{ displayLabelName("countries", "add-country", "code") }}
        </label>
        <Field
          name="code"
          as="input"
          type="text"
          rules="required"
          :disabled="submitting"
        />
        <span class="error-message" v-if="errors.code">
          {{ errors.code }}
        </span>
      </div>
      <div class="form-group input">
        <label class="tiny-label">
          {{ displayLabelName("countries", "add-country", "dial-code") }}
        </label>
        <Field
          name="dial_code"
          as="input"
          type="text"
          rules="required"
          :disabled="submitting"
        />
        <span class="error-message" v-if="errors.dial_code">
          {{ errors.dial_code }}
        </span>
      </div>
      <div class="form-group submit">
        <button class="btn rounded light" type="submit" :disabled="submitting">
          {{ displayLabelName("countries", "add-country", "save-changes") }}
        </button>
      </div>
    </VeeForm>
  </mobile-screen>
</template>

<script>
import httpServiceAuth from "@/services/http-service";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "AddCountry",
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    ...mapState("user", ["userId"])
  },
  methods: {
    ...mapActions("countries", ["getCountries"]),
    submitForm(values, { resetForm }) {
      if (values) {
        this.submitting = true;
        this.submitMasterData(values, resetForm);
      }
    },
    submitMasterData(values, resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(apiEndpoints.master.countries, values)
        .then(() => {
          resetForm();
          this.getCountries();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
